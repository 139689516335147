import React, { useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import nuevos from "../../assets/images/secciones/nuevos.png";
import usados from "../../assets/images/secciones/usados.png";
import planes from "../../assets/images/secciones/planes.png";
import servicios from "../../assets/images/secciones/servicios.jpg";
import repuestos from "../../assets/images/secciones/repuestos.jpg";
import accesorios from "../../assets/images/secciones/accesorios.jpg";
import carousel_1 from "../../assets/images/carousel/1.jpg";
import carousel_2 from "../../assets/images/carousel/2.jpg";
import carousel_3 from "../../assets/images/carousel/3.jpg";
import carousel_4 from "../../assets/images/carousel/4.jpg";
//import wall from "../../assets/images/carousel/wall.jpg";
//import ahora3 from "../../assets/images/logos/ahora3.jpg";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Row
        className="m-0 mb-4 mb-2 p-0 shadow"
        /*  style={{
          backgroundImage: `url(${wall})`,
        }} */
      >
        <Col
          xs={12}
          /* lg={{ span: "8", offset: "2" }} */
          className="text-center smNoPadding m-0 p-0"
        >
          <Carousel fade className="carouselHeight">
            <Carousel.Item>
              <img
                className="d-block w-100 carouselItemHeight"
                src={carousel_1}
                alt="First slide"
              />
              <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.3)" }}>
                <h3>NUEVO COROLLA CROSS</h3>
                <p>Nuevo diseño exterior, mayor tecnología y seguridad.</p>
                <a
                  href="https://store.autoprana.com.ar/vtf/"
                  target="_self"
                  rel="noreferrer"
                >
                  <Button
                    size="sm"
                    variant="outline-light"
                    style={{
                      backgroundColor: "red",
                      borderColor: "transparent",
                      fontWeight: "bold",
                    }}
                    className="mb-2"
                  >
                    Ver disponibilidad
                  </Button>
                </a>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carouselItemHeight"
                src={carousel_2}
                alt="First slide"
              />
              <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.3)" }}>
                <h3>HILUX SRX 2024</h3>
                <p>Tu próxima Hilux 0km</p>
                <a
                  href="https://store.autoprana.com.ar/vtf/"
                  target="_self"
                  rel="noreferrer"
                >
                  <Button
                    size="sm"
                    variant="outline-light"
                    style={{
                      backgroundColor: "red",
                      borderColor: "transparent",
                      fontWeight: "bold",
                    }}
                    className="mb-2"
                  >
                    Ver disponibilidad
                  </Button>
                </a>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carouselItemHeight"
                src={carousel_3}
                alt="First slide"
              />
              <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.3)" }}>
                <h3>TURNOS ONLINE</h3>
                <p>Obtené tu turno al taller</p>
                <a
                  href="https://store.autoprana.com.ar/#/servicios"
                  target="_self"
                  rel="noreferrer"
                >
                  <Button
                    size="sm"
                    variant="outline-light"
                    style={{
                      backgroundColor: "red",
                      borderColor: "transparent",
                      fontWeight: "bold",
                    }}
                    className="mb-2"
                  >
                    Reservar turno
                  </Button>
                </a>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 carouselItemHeight"
                src={carousel_4}
                alt="First slide"
              />
              <Carousel.Caption style={{ background: "rgba(0, 0, 0, 0.3)" }}>
                <h3>PRANA USADOS</h3>
                <p>Encontrá tu proximo vehículo</p>
                <a
                  href="https://store.autoprana.com.ar/#/usados"
                  target="_self"
                  rel="noreferrer"
                >
                  <Button
                    size="sm"
                    variant="outline-light"
                    style={{
                      backgroundColor: "red",
                      borderColor: "transparent",
                      fontWeight: "bold",
                    }}
                    className="mb-2"
                  >
                    Ver catálogo
                  </Button>
                </a>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="m-0 mb-4 p-0 pt-4 rowColorOpacity my-2">
        <Col xs={12} lg={{ span: "10", offset: "1" }}>
          <Row className="m-0 p-0">
            <Col xs={12} lg={4}>
              <a href="https://store.autoprana.com.ar/vtf">
                <Col>
                  <Badge className="badgeStyle">0KM</Badge>
                  <Image
                    width="100%"
                    src={nuevos}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </a>
            </Col>
            <Col xs={12} lg={4}>
              <Link to="/usados">
                <Col>
                  <Badge className="badgeStyle">USADOS</Badge>
                  <Image
                    width="100%"
                    src={usados}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Link>
            </Col>
            {/* <Col xs={12} lg={4}>
              <Link to="/planes">
                <Col>
                  <Badge className="badgeStyle">PLANES</Badge>
                  <Image
                    width="100%"
                    src={planes}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Link>
            </Col> */}
            <Col xs={12} lg={4}>
              <a href="https://toyotaplan.com.ar/">
                <Col>
                  <Badge className="badgeStyle">PLANES</Badge>
                  <Image
                    width="100%"
                    src={planes}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="m-0 mb-4 p-0 py-4 rowColorOpacity  my-2">
        <Col xs={12} lg={{ span: "10", offset: "1" }} className="">
          <Row className="m-0 p-0">
            <Col xs={12} lg={4} className="my-4">
              <Link to="/accesorios">
                <Col>
                  <Badge className="badgeStyle">ACCESORIOS</Badge>
                  <Image
                    width="100%"
                    src={accesorios}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Link>
            </Col>
            <Col xs={12} lg={4} className="my-4">
              <Link to="/servicios">
                <Col>
                  <Badge className="badgeStyle">SERVICIOS</Badge>
                  <Image
                    width="100%"
                    src={servicios}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Link>
            </Col>
            <Col xs={12} lg={4} className="my-4">
              <Link to="/repuestos">
                <Col>
                  <Badge className="badgeStyle">REPUESTOS</Badge>
                  <Image
                    width="100%"
                    src={repuestos}
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row className="m-0 p-0 mb-5">
        <Col xs={12} lg={{ span: "10", offset: "1" }}>
          <Card>
            <Card.Body style={{ fontSize: "18px" }} className="text-center">
              <FontAwesomeIcon icon="info-circle" style={{ color: "black" }} />{" "}
              accede a nuestros productos y servicios con la mejor financiación
              <img src={ahora3} alt="ahora3 logo" width="35px" />
              <br />
              <FontAwesomeIcon
                icon="info-circle"
                style={{ color: "black" }}
              />{" "}
              contamos con vehículo 0km para entrega inmediata solo con reserva
              online
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <Row className="m-0 p-0 mb-5">
        <Col xs={12} lg={{ span: "6", offset: "3" }}>
          <Card className="shadowLG">
            <Card.Body className="m-0 p-0">
              <a href="https://store.autoprana.com.ar/#/repuestos">
                <Image
                  width="100%"
                  src="https://store.autoprana.com.ar/img/imgSitio/repuestosSeleccionados.jpg"
                  style={{ border: "1px solid lightGray", borderRadius: "5px" }}
                />
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
